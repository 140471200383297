import {createContext, PropsWithChildren, ReactNode, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {animated, config, useSpring} from 'react-spring';
import useMeasureDirty from 'react-use/lib/useMeasureDirty';
import {v4 as uuidv4} from 'uuid'
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons';

interface ContextType {
    openPanel: string;
    setOpenPanel: (id: string) => void
}

const INITIAL = {
    openPanel: '',
    setOpenPanel: (id: string) => {
    }
}
const CONTEXT = createContext<ContextType>(INITIAL)

export const Accordion = (props: { children: PropsWithChildren<{}> }) => {
    const [openPanel, setOpenPanel] = useState('')
    const memoizedContext = useMemo(() => {
        return {setOpenPanel, openPanel}
    }, [setOpenPanel, openPanel])
    return (
        <CONTEXT.Provider value={memoizedContext}>
            {props.children}
        </CONTEXT.Provider>
    )
}

function useAccordionContext() {
    return useContext<ContextType>(CONTEXT)
}

interface ItemProps {
    title: string;
    children: ReactNode;
}

export const AccordionItem = (props: ItemProps) => {
    const context = useAccordionContext()
    const uuid = useMemo(() => uuidv4(), [])
    const isOpen = uuid === context.openPanel
    const contentRef = useRef(null)
    const {height} = useMeasureDirty(contentRef)
    const targetHeight = Math.min(height, 200)
    const contentSpring = useSpring({
        height: isOpen ? targetHeight : 0,
        onStart: () => {
            setIsResting(false)
        },
        onRest: () => {
            setIsResting(true)
        }
    })
    const [isResting, setIsResting] = useState(true)
    const buttonSpring = useSpring({rotate: isOpen ? 180 : 0, config: config.wobbly})
    const onHeaderClick = () => {
        if (isOpen) {
            context.setOpenPanel('')
        } else {
            context.setOpenPanel(uuid)
        }
    }
    return (
        <div className="text-rodin-900">
            <button onClick={onHeaderClick} type="button"
                    className="group w-full text-left font-black py-3 flex justify-between outline-none">
                {props.title}
                <animated.div style={buttonSpring}
                              className={classNames('w-4 h-4 rounded-full grid place-items-center origin-center bg-rodin-900 group-focus-visible:bg-gold-500')}>
                    <FontAwesomeIcon icon={faChevronDown} className="text-white" width={8} height={8}/>
                </animated.div>
            </button>
            <animated.div style={contentSpring} aria-hidden={!isOpen} tabIndex={isOpen ? undefined : -1}
                          className={classNames('overflow-y-auto overflow-x-hidden',{'hidden':isResting && !isOpen})}>
                <div ref={contentRef}>
                    <div className="pb-4 text-sm leading-relaxed pr-4">
                        {props.children}
                    </div>
                </div>
            </animated.div>
        </div>
    )
}
