import {createContext, ReactNode, RefObject, useContext, useEffect, useMemo, useRef, useState} from 'react';
import useMeasureDirty from 'react-use/lib/useMeasureDirty';
import {useHoverDirty, useScroll, useWindowScroll} from 'react-use';
import {useRect} from 'ease/use-rect';

interface Overlay {
    id: string;
    rect: any;
}

type EaseContextType = {
    overlays: Overlay[];
    attachOverlay: (id: string, rect: any) => void;
    detachOverlay: (id: string) => void
}

const INIT: EaseContextType = {
    overlays: [],
    attachOverlay: () => {
    },
    detachOverlay: () => {
    }
}

const CONTEXT = createContext(INIT)

interface ProviderProps {
    children: ReactNode;
}

export function useEaseOverlay(id: string) {
    const context = useContext(CONTEXT)
    const ref = useRef(null)
    const {height, width, left, top} = useRect(ref)
    const enter = () => {
        console.log('enter', {width, height, left, top})
        context.attachOverlay(id, {width, height, left, top})
    }
    const leave = () => {
        console.log('leave')
        context.detachOverlay(id);
    }
    return {onMouseEnter: enter, onMouseLeave: leave, ref};
}

const OverlayHolder = () => {
    const overlays = useContext(CONTEXT).overlays;
    const windowScroll = useWindowScroll()
    return <div id="ease_overlays z-50">
        {overlays.map(config => (
            <div className="absolute pointer-events-none border-2 border-slate-500 rounded-lg" style={{
                width: config.rect.width,
                left: config.rect.left,
                top: config.rect.top,
                height: config.rect.height
            }}
                 key={config.id}/>
        ))
        }
    </div>
}

export const EaseOverlayProvider = (props: ProviderProps) => {
    const [overlays, setOverlays] = useState<Overlay[]>([])
    const attachOverlay = (id: string, rect: any) => {
        setOverlays(o => [...o, {id, rect}])
    }
    const detachOverlay = (id: string) => {
        setOverlays(o => o.filter(it => it.id !== id))
    }
    const memoizedContext = useMemo(() => ({
        detachOverlay,
        attachOverlay,
        overlays
    }), [overlays])
    return <>
        <CONTEXT.Provider value={memoizedContext}>
            {props.children}
            <OverlayHolder/>
        </CONTEXT.Provider>
    </>
}
