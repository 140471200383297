import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactNode, useRef} from 'react';
import useMeasureDirty from 'react-use/lib/useMeasureDirty';
import {animated, config, useSpring, useTransition} from 'react-spring';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons';
import {faCommentQuestion} from '@fortawesome/pro-light-svg-icons';
import {useToggleState} from '@react-stately/toggle';
import {useToggleButton} from '@react-aria/button';
import {useFocusRing} from '@react-aria/focus';
import classNames from 'classnames';

interface Props {
children:ReactNode
}

export const FAQPanel = (props: Props) => {
    const contentRef = useRef(null)
    const mainRef = useRef(null)
    // const overlay = useEaseOverlay(mainRef)
    const {height: contentHeight} = useMeasureDirty(contentRef)
    return (
        <div className="bg-white shadow-lg rounded-[30px] px-8 py-12 flex flex-col items-center" ref={mainRef}
             role="group">
            <div
                className="w-24 h-24 rounded-full border-2 border-gold-300 flex-grow-0 flex-shrink-0 flex items-center justify-center mb-5">
                <FontAwesomeIcon icon={faCommentQuestion} width={36} height={36}
                                 className="!h-9 text-gold-300 shrink-0 grow-0"/>
            </div>
            <div className="text-rodin-900 font-black text-[2rem]">Ofta settir spurningar</div>
            <div className="flex-1 relative w-full mt-12 divide-y border-y" ref={contentRef}>
                {props.children}
            </div>
        </div>
    )
}

interface AccordionProps {
    parentContentHeight: number;
    title: string;
    content: ReactNode;
}

const InfoPanelAccordion = (props: AccordionProps) => {
    const state = useToggleState()
    const ref = useRef(null)
    const {buttonProps, isPressed} = useToggleButton({elementType: 'button'}, state, ref)
    const open = state.isSelected

    // const [open, setOpen] = useState(false)
    const contentRef = useRef(null)
    // const contentSpring = useSpring({height: open ? props.parentContentHeight : 0, config: {frequency: .4, damping: 1}})
    const buttonSpring = useSpring({rotate: open ? 180 : 0, config: config.wobbly})
    const contentTransition = useTransition(open, {
        from: {
            height: 0
        },
        initial: {
            height: 0
        },
        enter: {
            height: props.parentContentHeight
        },
        leave: {
            height: 0
        },
        expires: true
    })
    const {focusProps, isFocusVisible} = useFocusRing()
    return (
        <div className="w-full border-b absolute bottom-0 bg-white">
            <button
                onFocus={focusProps.onFocus}
                onBlur={focusProps.onBlur}
                className="py-3 select-none w-full cursor-pointer border-t font-black text-rodin-900 outline-none flex justify-between items-center group" {...buttonProps}
                ref={ref}>
                {props.title}
                <animated.div style={buttonSpring}
                              className={classNames('w-4 h-4 rounded-full grid place-items-center origin-center', {
                                  'bg-gold-500': isFocusVisible,
                                  'bg-rodin-900': !isFocusVisible
                              })}>
                    <FontAwesomeIcon icon={faChevronUp} className="text-white" width={8} height={8}/>
                </animated.div>
            </button>

        </div>
    )
}
