import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {JSXElementConstructor, ReactNode} from 'react';
import {faCheck} from '@fortawesome/pro-solid-svg-icons';

interface ListProps {
    children: ReactNode;
}

interface ItemProps {
    children: ReactNode;
}

const Item = (props: ItemProps) => {
    return (
        <li className="relative text-[#464646] text-base pl-8">
            <FontAwesomeIcon icon={faCheck} className="text-gold-500 absolute left-0 top-[6px]"/>
            {props.children}
        </li>
    )
}

interface SimpleListProps {
    items: string[]
}

const SimpleList = (props: SimpleListProps) => {
    return (
        <List>
            {props.items.map((item, index) => <Item key={index}>{item}</Item>)}
        </List>
    )
}

type ListType = JSXElementConstructor<ListProps> & {
    Item: JSXElementConstructor<ItemProps>,
    Basic: JSXElementConstructor<SimpleListProps>
}

export const List: ListType = (props: ListProps) => {
    return (
        <ul className="space-y-4">
            {props.children}
        </ul>
    )
}

List.Item = Item
List.Basic = SimpleList
