import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactNode, useRef} from 'react';
import useMeasureDirty from 'react-use/lib/useMeasureDirty';
import {animated, config, useSpring, useTransition} from 'react-spring';
import {faChevronUp} from '@fortawesome/pro-solid-svg-icons';
import {useToggleState} from '@react-stately/toggle';
import {useToggleButton} from '@react-aria/button';
import {useFocusRing} from '@react-aria/focus';
import classNames from 'classnames';
import {useEaseOverlay} from 'ease/overlay';

interface Props {
    icon: IconDefinition;
    title: string;
    accordionTitle: string;
    accordionContent: ReactNode;
    children?: ReactNode;
}


export const InfoPanel = (props: Props) => {
    const {ref} = useEaseOverlay(props.title)
    const contentRef = useRef(null)
    // const overlay = useEaseOverlay(mainRef)
    const {height: contentHeight} = useMeasureDirty(contentRef)
    return (
        <div className="bg-white shadow-lg rounded-[30px] px-8 py-12 flex flex-col items-center" ref={ref}
             role="listitem">
            <div
                className="w-24 h-24 rounded-full border-2 border-gold-300 flex-grow-0 flex-shrink-0 flex items-center justify-center mb-5">
                <FontAwesomeIcon icon={props.icon} width={36} height={36}
                                 className="!h-9 text-gold-300 shrink-0 grow-0"/>
            </div>
            <div className="text-rodin-900 font-black text-[2rem]">{props.title}</div>
            <div className="flex-1 relative w-full mt-6 pb-[50px] min-h-[340px]" ref={contentRef}>
                <div className="pb-16 pt-8">
                    {props.children}
                </div>
                <InfoPanelAccordion parentContentHeight={contentHeight} title={props.accordionTitle}
                                    content={props.accordionContent}/>
            </div>
        </div>
    )
}

interface AccordionProps {
    parentContentHeight: number;
    title: string;
    content: ReactNode;
}

const InfoPanelAccordion = (props: AccordionProps) => {
    const state = useToggleState()
    const ref = useRef(null)
    const {buttonProps, isPressed} = useToggleButton({elementType: 'button'}, state, ref)
    const open = state.isSelected

    // const [open, setOpen] = useState(false)
    const contentRef = useRef(null)
    // const contentSpring = useSpring({height: open ? props.parentContentHeight : 0, config: {frequency: .4, damping: 1}})
    const buttonSpring = useSpring({rotate: open ? 180 : 0, config: config.wobbly})
    const contentTransition = useTransition(open, {
        from: {
            height: 0
        },
        initial: {
            height: 0
        },
        enter: {
            height: props.parentContentHeight
        },
        leave: {
            height: 0
        },
        expires: true
    })
    const {focusProps, isFocusVisible} = useFocusRing()
    return (
        <div className="w-full border-b absolute bottom-0 bg-white">
            <button
                onFocus={focusProps.onFocus}
                onBlur={focusProps.onBlur}
                className="py-3 select-none w-full cursor-pointer border-t font-black text-rodin-900 outline-none flex justify-between items-center group" {...buttonProps}
                ref={ref}>
                {props.title}
                <animated.div style={buttonSpring}
                              className={classNames('w-4 h-4 rounded-full grid place-items-center origin-center', {
                                  'bg-gold-500': isFocusVisible,
                                  'bg-rodin-900': !isFocusVisible
                              })}>
                    <FontAwesomeIcon icon={faChevronUp} className="text-white" width={8} height={8}/>
                </animated.div>
            </button>
            {contentTransition((style, open) => open &&
                <animated.div style={style}
                              className="overflow-y-auto rounded-md outline-none focus-visible:ring-2 ring-opacity-20 ring-gold-500"
                              tabIndex={!state.isSelected ? -1 : undefined}
                              aria-hidden={!state.isSelected}>
                    <div ref={contentRef} className="pr-8 pb-4 pt-1">
                        {props.content}
                    </div>
                </animated.div>)}
        </div>
    )
}
